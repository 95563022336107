/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.logo img, .logo svg, .logo-lg img, .logo-lg svg {
    vertical-align: middle;
    height: 54px !important;
    width: 100%;
}
.navbar-menu{
    background-color: #405189 !important;
}
.navbar-menu .navbar-nav .nav-link{color: #abb9e8 !important;}
.pagination-wrap.hstack.gap-2 ul li {
    margin-left: 0.3rem;
    border-radius: 0.25rem!important;
    border: 1px solid var(--vz-border-color);
    background-color: var(--vz-card-bg-custom);
    color: var(--vz-link-color);
    padding: 5px 14px;
    list-style: none;
}
li.nav-item.active a {
  color: #fff !important;
}
.pagination-wrap.hstack.gap-2 ul {
    display: flex;
}
.pagination-wrap.hstack.gap-2 ul li.selected {
    background-color: var(--vz-link-color);
    color: #fff;
}

.action .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    font-size: 15px;
    line-height: 15px;
    content: "" !important;
    font-family: "Material Design Icons";
  }

  .action .dropdown-toggle{
    color: #3577f1;
    background-color: rgba(53,119,241,.1) !important;
    border-color: transparent !important;
  }
  .LYwHa {
    overflow-x: initial !important;
    overflow-y: initial !important;
}
body {
  background-color: #aec9bb17;
}
a.nav-link.menu-link.collapsed:after {
    display: block;
    content: "\f0142";
    font-family: "Material Design Icons";
    margin-left: auto;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s,-webkit-transform .2s;
    font-size: 1.05rem;
    position: absolute;
    right: 18px;
    color: var(--vz-vertical-menu-title-color);
}
li.nav-item.is-expanded a.nav-link.menu-link.collapsed:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    color: var(--vz-vertical-menu-item-active-color);
}
.dl dt {
    float: left;
    width: 45%;
    padding: 0!important;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* font-size: 13px; */
  }
  .dl dd {
    margin-left: 50% !important;
    /* font-size: 13px; */
  }
  .dl dd {
    margin-left: 50% !important;
    /* font-size: 13px; */
  }

  .table>:not(caption)>*>*{
    padding: 0.1rem 1px 0.5rem !important;
  }

  .logo img, .logo svg, .logo-lg img, .logo-lg svg {
    vertical-align: middle;
    height: 54px !important;
    width: auto !important;
}

.accordion-item {
  color: var(--vz-accordion-color);
  background-color: #e9ebec4f !important;
  border: var(--vz-accordion-border-width) solid var(--vz-accordion-border-color);
}
iframe {
  display: none;
}
.showDemande .live-preview .flex-wrap{justify-content: center;}

.document iframe {
  display: block !important;
}
.logo img {
  vertical-align: middle;
  height: auto !important;
  width: 27% !important;
  margin-top: 7px; 
}
.avatar-title.bg-light.text-secondary.rounded.fs-24 i:before {
  left: 0px;
  top: 0px;
}
li.nav-item.active {
  color: #fff !important;
  background: #d2f1e18a;
}
.navbar-menu .navbar-nav .nav-item.active a.nav-link.menu-link{
  color: #fff !important;
}
ul.nav.nav-sm.flex-column.test li.nav-item.active .nav-link {
  color: #fff !important;
}
.navbar-menu .navbar-nav .nav-sm .nav-link:before {

  background-color: #fff;
}
.userprofile i {
  font-size: 24px;
}
.page-content{overflow-x: hidden;}
.card.card-animate p {
  font-size: 12px;
}
ul#controlled-tab-example {
  justify-content: center;
}
.navbar-menu .navbar-nav .nav-link{
  color: #fff !important;
}
.navbar-menu {
  background-color: #03a551 !important;
}